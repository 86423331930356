import {useFLMStore} from "@/stores/flm";
import {ref, watch} from "vue";
import {storeToRefs} from "pinia";
import {UtilityId} from "@/stores/flm/types";
import {useRoute} from "vue-router/composables";
import {SUBTITLE_CUSTOMER, SUBTITLE_UTILITY} from "@/router/routes";

const LOGOS: Record<UtilityId, string | null> = {
  GMP: '/logo/gmp.svg',
  VEC: '/logo/vec.svg',
  BED: null,
};

export function useUtilityLogo() {
  const {utilityId, siteId, site} = storeToRefs(useFLMStore());
  const route = useRoute();

  const logo = ref<string | null | undefined>(getLogo());

  // change logo when utilityId or siteId changes
  watch(utilityId, () => {
    if (utilityId.value)
      updateLogo();
  });
  watch(siteId, () => {
    if (site.value)
      updateLogo();
  });
  watch((route), updateLogo);

  // if utility page, use selected utility logo
  // if customer page, use selected site's utility logo
  // else, return undefined (no change)
  function getLogo(): string | null | undefined {
    if (route.meta?.subtitle == SUBTITLE_UTILITY && utilityId.value) {
      return LOGOS[utilityId.value];
    } else if (route.meta?.subtitle == SUBTITLE_CUSTOMER && site.value) {
      return LOGOS[site.value?.utility];
    }
  }

  function updateLogo() {
    // null means no logo, undefined means use previous logo
    const newLogo = getLogo();
    logo.value = newLogo === null ? null : newLogo ?? logo.value;
  }

  return logo;
}
