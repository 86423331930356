import {DateTime, Interval} from "luxon";

// https://stackoverflow.com/questions/65692061/casting-dates-properly-from-an-api-response-in-typescript
const ISO_DATE_FORMAT = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;
function isIsoDateString(value: any): boolean {
  return value && typeof value === "string" && ISO_DATE_FORMAT.test(value);
}

export function luxonifyDates(data: any) {
  if (isIsoDateString(data))
    return DateTime.fromISO(data);

  if (data === null || data === undefined || typeof data !== "object")
    return data;

  if (Array.isArray(data))
    return data.map((x: any) => luxonifyDates(x));

  // convert objects like {start: DateTime, end: DateTime} to luxon Interval
  if (Object.keys(data).length === 2 && isIsoDateString(data.start) && isIsoDateString(data.end))
    return Interval.fromDateTimes(DateTime.fromISO(data.start), DateTime.fromISO(data.end));

  return Object.fromEntries(Object.entries(data).map(([key, value]): [string, any] => {
    return [key, luxonifyDates(value)];
  }));
}

export function stringifyDates(data: any) {
  if (data instanceof DateTime)
    return data.toJSON();

  if (data === null || data === undefined || typeof data !== "object")
    return data;

  if (Array.isArray(data))
    return data.map((x: any) => stringifyDates(x));

  if (data instanceof Interval)
    return {start: data.start?.toJSON() ?? null, end: data.end?.toJSON() ?? null};

  return Object.fromEntries(Object.entries(data).map(([key, value]): [string, any] => {
    return [key, stringifyDates(value)];
  }));
}
