import {defineStore} from "pinia";
import keycloak from "@/plugins/keycloak";
import humps from "humps";

export const useAuthStore = defineStore('auth', {
  state: () => ({
    roles: {
      accessDashboard: true,
      viewAdmin: false,
      viewPageModelAnalysis: false,
      accessAllSites: false,
    },
  }),
  getters: {},
  actions: {
    setRole(role, status) {
      // TODO: temporary hacky solution to not require ACCESS_DASHBOARD be a role on this dashboard without
      // changing core or utility functionality
      // override set role to never change access dashboard away from true since it's not actaully
      // a used role on this dashboard
      if (role === "accessDashboard")
        return;
      this.roles[role] = status;
    },
    updateRoles() {
      const roles = keycloak.tokenParsed!.resource_access?.[process.env.VUE_APP_KEYCLOAK_CLIENT_ID]?.roles;
      Object.keys(this.roles).forEach((role) => {
        const kcName = humps.decamelize(role, {separator: "-"});
        this.setRole(role, roles?.includes(kcName) ?? false);
      });
    },
  },
});

export type AuthStore = ReturnType<typeof useAuthStore>;
