import routes from "@/router/routes";
import {authGuard, externalLinkGuard, globalErrorGuard, subtitleGuard, titleGuard} from "@/router/guards";
import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: "hash",
  base: "/",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition)
      return savedPosition;

    return {
      x: 0,
      y: 0,
    };
  },
});

router.beforeEach(authGuard);

router.beforeEach(externalLinkGuard);
router.beforeEach(titleGuard);
router.beforeEach(subtitleGuard);
router.beforeEach(globalErrorGuard);

routes.forEach((route) => {
  router.addRoute(route);
});

export default router;
