import axios, {AxiosInstance, AxiosResponse, InternalAxiosRequestConfig} from 'axios';
import {camelizeKeys, decamelizeKeys} from 'humps';
import keycloak from "@/plugins/keycloak";
import Vue from "vue";
import {luxonifyDates, stringifyDates} from "@/api/util";
import {useAppStore} from "@/stores/app";
import {GlobalError} from "@/stores/app/types";

const api: AxiosInstance = axios.create({
  baseURL: `${process.env.VUE_APP_API_BASE_URL}/dashboard`,
  timeout: 15000,
});

api.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  config.headers.Authorization = `Bearer ${keycloak?.token}`;
  return config;
});

// https://medium.com/javascript-in-plain-english/configuring-a-camelcase-to-snake-case-parser-with-axios-9fa34fd3b16f
// Axios middleware to convert all api request & responses to camelCase
api.interceptors.request.use((config) => {
  const newConfig = {...config};
  const urlParams = decamelizeKeys(Object.fromEntries(new URLSearchParams(config.url!.split("?")[1])));
  // @ts-ignore
  const paramsStr = "?" + new URLSearchParams(urlParams).toString();
  newConfig.url = config.url!.split("?")[0] + (paramsStr.length > 1 ? paramsStr : "");

  if (newConfig.headers?.['Content-Type'] === 'multipart/form-data')
    return newConfig;
  if (config.params) {
    newConfig.params = decamelizeKeys(config.params);
  }
  if (config.data) {
    // noinspection TypeScriptValidateTypes
    newConfig.data = decamelizeKeys(config.data);
  }
  return newConfig;
});

api.interceptors.response.use(
    (response) => {
      if (response.data && response.headers?.['content-type'] === 'application/json') {
        response.data = camelizeKeys(response.data);
      }
      return response;
    }, (error) => {
      const response = error.response;
      if (response) {
        if (response.data && response.headers?.['content-type'] === 'application/json') {
          response.data = camelizeKeys(response.data);
        }
      }
      return Promise.reject(error);
    },
);

api.interceptors.response.use((response: AxiosResponse) => {
  if (response.data && response.headers?.['content-type'] === 'application/json')
    response.data = luxonifyDates(response.data);
  return response;
});
api.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const newConfig = {...config};
  newConfig.data = stringifyDates(config.data);
  newConfig.params = stringifyDates(config.params);
  return newConfig;
});

// global error handling

export enum ErrorMode {
  NONE,
  NOTIFY,
  SET_ERROR,
}

api.interceptors.response.use((response) => response, (error) => {
  const response = error.response;
  console.error(response ?? error);

  const appStore = useAppStore();

  if (!response) {
    appStore.showError("Request failed!");
    return Promise.reject(error);
  }

  const errorMode = response.config.errorMode ?? ErrorMode.NOTIFY;
  if (!response || errorMode == ErrorMode.NONE)
    return Promise.reject(error);

  let errorData: Partial<GlobalError> | null = null;
  if (response.config.method.toUpperCase() === 'GET') {
    switch (response.status) {
      case 403:
        errorData = {
          title: 'Forbidden',
          message: response.data.message ?? "You do not have permission to access this resource.",
        };
        break;
      case 404:
        errorData = {
          title: 'Not Found',
          message: "The requested object could not be loaded.",
        };
        break;
      case 409:
        errorData = {
          title: "Conflict",
          message: "The object could not be created - perhaps it is a duplicate?",
        };
        break;
      case 500:
        errorData = {
          title: 'Server Error',
          message: "The request failed due to a server error.\nPlease try again later.",
        };
        break;
      default:
        errorData = {
          title: "Unexpected Error!",
          message: response.data?.message ?? "An unexpected error occurred!",
        };
        break;
    }
  } else {
    const verb = response.config.method === "DELETE" ? "deleting" : "saving";
    errorData = {
      title: 'Error',
      message: response.data?.message ?? `An unexpected error occurred while ${verb}!`,
    };
  }

  if (errorData) {
    if (errorMode === ErrorMode.NOTIFY) {
      appStore.showError(`${errorData.title}: ${errorData.message}`);
    } else if (errorMode ?? ErrorMode.SET_ERROR === ErrorMode.SET_ERROR) {
      appStore.setError({isDismissible: response.config.errorDismissible, ...errorData});
    }
  }

  return Promise.reject(error);
});

// LEGACY
Vue.prototype.$getAPI = function() {
  return api;
};

export default api;
