import api from "@/api";
import {AxiosRequestConfig} from "axios";

export interface UserSettings {
  email: string;
  phoneNumber: string;
  hasSmsNotifications: boolean;
  hasEmailNotifications: boolean;
}

export class UserAPIClient {
  async getSettings(userId: string, opts?: AxiosRequestConfig): Promise<{
    settings: UserSettings
  }> {
    const resp = await api.get(
        `/user/${userId}/settings/`,
        opts,
    );
    return resp.data;
  }

  async updateSettings(userId: string, newSettings: Partial<UserSettings>, opts?: AxiosRequestConfig): Promise<{
    settings: UserSettings
  }> {
    const resp = await api.patch(
        `/user/${userId}/settings/`,
        newSettings,
        opts,
    );
    return resp.data;
  }
}
