// time format for vue filter `| formatDate`
export default {
  zone: 'America/New_York',
  format: 'ff',
  formats: {
    'DS': 'MM/dd',
    'DM': 'MM/dd/yyyy',
    'DL': 'd MMMM yyyy',
    'TS': 'HH:mm',
    'DTS': 'MM/dd HH:mm',
    'DTM': 'MM/dd/yyyy HH:mm',
    'DTL': 'MMMM d, yyyy HH:mm',
  },
};
