import {Settings} from "luxon";

Settings.defaultZone = "America/New_York";
Settings.throwOnInvalid = true;

declare module 'luxon' {
  interface TSSettings {
    throwOnInvalid: true;
  }
}
