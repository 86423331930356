import {defineStore} from "pinia";
import configs from "@/configs";
import toolbar from "@/configs/toolbar";
import navigation from "@/configs/navigation";
import {useAuthStore} from "@/stores/auth";
import router from "@/router";
import {useFLMStore} from "@/stores/flm";
import vuetify from "@/plugins/vuetify";
import {NavMenuConfig} from "@/components/navigation/types";
import {AppState, GlobalError, Toast} from "@/stores/app/types";

const {time, theme} = configs;

const {globalTheme, menuTheme, toolbarTheme, isToolbarDetached, isContentBoxed, isRTL} = theme;
export const useAppStore = defineStore('app', {
  state: (): AppState => ({
    product: {
      name: 'FLM Pilot',
      subtitle: null,
    },
    time,
    toolbar: {
      user: toolbar.user,
      components: [],
    },
    navigation: {
      // menu links
      menu: [],
      // footer links
      footer: [],
    },
    isDrawerExpanded: true,

    // themes and layout configurations
    globalTheme,
    menuTheme,
    toolbarTheme,
    isToolbarDetached,
    isContentBoxed,
    isRTL,

    // App.vue main toast
    toast: {
      show: false,
      color: 'black',
      message: '',
      timeout: 3000,
    },

    globalError: null,

    loading: 0,

    version: process.env.VUE_APP_VERSION || '0.0.0',
  }),
  getters: {
    getColors() {
      return theme[globalTheme];
    },
    timeFormats: (state): {[key: string]: string} => {
      return state.time.formats;
    },
    isLoading: (state) => {
      return state.loading > 0;
    },
  },
  actions: {
    init() {
      const theme = localStorage.getItem("globalTheme");
      if (theme)
        this.setGlobalTheme(theme);
    },
    setGlobalTheme(theme: string) {
      this.globalTheme = theme;
      vuetify.framework.theme.dark = theme === "dark";
      localStorage.setItem('globalTheme', theme);
    },

    showToast(toast: Toast) {
      if (this.toast.show)
        this.hideToast();

      const {color, timeout, message} = toast;

      this.toast = {
        message,
        color,
        timeout,
        show: true,
      };
    },
    hideToast() {
      this.toast.show = false;
    },
    showError(message = "Failed!") {
      if (this.toast.show)
        this.hideToast();

      setTimeout(() => {
        this.showToast({
          color: "error",
          message: message,
          timeout: 10000,
        });
      });
    },
    showDataRetrievalError() {
      this.showError("An error occurred while retrieving data.");
    },

    showSuccess(message = "Success!") {
      if (this.toast.show)
        this.hideToast();

      setTimeout(() => {
        this.showToast({
          color: "success",
          message,
          timeout: 3000,
        });
      });
    },
    setError(error: Partial<GlobalError>) {
      this.globalError = {
        title: error.title ?? "Error",
        message: error.message ?? "An error occurred",
        isDismissible: error.isDismissible ?? false,
      };
    },
    clearError() {
      this.globalError = null;
    },

    // set navigation items by importing config and removing items that either fail role check
    // or where passed filter function returns false
    initNavigation() {
      const authStore = useAuthStore();
      const flmStore = useFLMStore();
      this.navigation.menu = navigation.menu.map((category: NavMenuConfig) => {
        // remove routes where hasSiteRoles exists and returns false
        const items = category.items.filter((item) => {
          if (typeof item.canAccess !== 'undefined' && !item.canAccess())
            return false;

          if (item.to) {
            const {route} = router.resolve(item.to);
            // remove where role check fails
            if (route?.meta && route.meta.hasAuth && !route.meta.hasAuth!(authStore, flmStore))
              return false;

            const site = flmStore.site;
            const siteRoles = flmStore.siteRoles;
            if ((typeof site !== "undefined") && route?.meta && "hasSiteRoles" in route.meta
                && !route.meta.hasSiteRoles(siteRoles, authStore))
              return false;
          }

          // default true
          return true;
        });
        return {...category, items};
      }).filter((category) => {
        // remove categories that contain no links
        return category.items.length > 0;
      });
    },

    withLoading<T>(p: Promise<T>, delay = 0): Promise<T> {
      this.loading += 1;
      return p.finally(() => {
        setTimeout(() => this.loading -= 1, delay);
      });
    },
  },
});

export type AppStore = ReturnType<typeof useAppStore>;
