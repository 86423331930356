export function formatPercentage(value: number | undefined | null, places = 0): string {
  if (value === null || typeof value === 'undefined')
    return "";

  value = 100 * value;
  return `${value.toFixed(places)}%`;
}

export function formatCurrency(value: number | undefined | null, places = 2): string {
  if (typeof value !== "number") {
    return "";
  }
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: places,
    maximumFractionDigits: places,
  });
  return formatter.format(value);
}

export function formatNumber(value: number | undefined | null, places = 0, unit?: string): string {
  if (typeof value !== "number")
    return "";

  let formatted = value.toLocaleString('en-US', {
    minimumFractionDigits: places,
    maximumFractionDigits: places,
  });
  if (unit)
    formatted += ` ${unit}`;
  return formatted;
}
