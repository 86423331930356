import _ from "lodash";
import {DateTime} from "luxon";
import {EventPerformance} from "@/api/client/site";

export interface MonthlyEventPerformance {
  month: string;
  enrolled: number;
  actual: number;
  baseline: number;
  score: number;
}

export const calculateMonthlyEventPerformance = (events: Array<EventPerformance>, commissionMonth: DateTime) => {
  events = events.filter((event: EventPerformance) => DateTime.now() > event.endDt);
  // reduce events list to object my month; ie {yyyy-mm: {enrolled: [1, 2],...}...}
  const months = events?.reduce((accs, event) => {
    const month = event.startDt.startOf("month").toFormat("yyyy-MM");
    if (!accs[month]) {
      accs[month] = {month: month, enrolled: [], baseline: [], actual: [], score: []};
    }

    // append each property to array
    for (const k in event) {
      if (Object.prototype.hasOwnProperty.call(event, k))
        accs[month][k]?.push(event[k]);
    }
    return accs;
  }, {}) ?? {};

  // fill in gaps with empties, which will become NaNs
  let dt = DateTime.local().startOf('month');
  while (dt >= commissionMonth) {
    const k = dt.toFormat('yyyy-MM');
    if (!(k in months)) {
      months[k] = {
        month: k,
        enrolled: [],
        actual: [],
        baseline: [],
        score: [],
      };
    }
    dt = dt.minus({months: 1});
  }

  return Object.values(months).map((acc: any) => {
    if (acc.baseline.includes(null) || acc.actual.includes(null))
      return {
        month: acc.month,
        enrolled: null,
        actual: null,
        baseline: null,
        score: null,
      };
    return {
      month: acc.month,
      enrolled: _.mean(acc.enrolled),
      actual: _.mean(acc.actual),
      baseline: _.mean(acc.baseline),
      score: _.mean(acc.score),
    };
  }).sort((a, b) => (a.month < b.month) ? 1 : -1);
};
