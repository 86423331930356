import time from './time';
import theme from './theme';
import toolbar from './toolbar';
import navigation from './navigation';

export default {
  // time configs
  time,

  // theme configs
  theme,

  // toolbar configs
  toolbar,

  // navigation configs
  navigation,
};
