import Vue from 'vue';
import {useAppStore} from "@/stores/app";

/**
 * Copy to clipboard the text passed
 * @param text string to copy
 * @param toastText message to appear on the toast notification
 */
Vue.prototype.$clipboard = function(text: string, toastText = 'Copied to clipboard!') {
  const el = document.createElement('textarea');

  el.value = text;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);

  const store = useAppStore();
  store.showSuccess(toastText);
};
