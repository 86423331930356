import {Account, Meter, SubmeterField} from "@/stores/flm/types";
import {DateTime, Interval} from "luxon";
import {formatNumber} from "@/util/format";
import {useFLMStore} from "@/stores/flm";
import {getQuery, queryClient} from "@/api/queryClient";
import {client} from "@/api/client";
import {
  DataType,
  ItemStyle,
  SourceType,
  TelemetryPoint,
  TelemetryReading,
  TelemetryReadingRange,
  ValuePeriod,
} from "@/components/telemetry/types";
import {DataGroupingApproximationValue} from "highcharts";
import {getEventPerformanceQueryKey} from "@/composables/queries/useEventPerformanceQuery";
import {DailyEventPrediction, SiteEvent} from "@/api/client/site";
import {WeatherObservationRow} from "@/api/client/telemetry";

export abstract class TelemetryItem {
  readonly SOURCE_TYPE: SourceType = SourceType.ROWS;

  protected _dataType: DataType = DataType.NUMBER;
  protected _unit: string | null = null;
  protected _precision: number | undefined = 1;
  protected _dataGroupingApproximation: DataGroupingApproximationValue | null = 'average';

  style: ItemStyle;
  order: number;

  constructor(style?: Partial<ItemStyle>, order?: number) {
    this.style = {
      plotStyle: 'step',
      colorIndex: undefined,
      className: undefined,
      ...style,
    };
    this.order = order ?? 0;
  }

  get dataType(): DataType {
    return this._dataType;
  }

  abstract get name(): string;

  get unit(): string | null {
    return this._unit;
  }

  get precision(): number | undefined {
    return this._precision;
  }

  // put all of same unit on same axis id
  // if no unit, 2 shared axes for booleans and enums
  // otherwise, default to axis per item
  get yAxisId(): string {
    if (this.unit)
      return this.unit;
    if (this.dataType === DataType.BOOLEAN)
      return 'boolean';
    if (this.dataType === DataType.ENUM)
      return 'enum';
    return this.name;
  }

  get isEnumLike(): boolean {
    return [DataType.ENUM, DataType.BOOLEAN].includes(this.dataType);
  }

  get dataGroupingApproximation(): DataGroupingApproximationValue | null {
    return this._dataGroupingApproximation;
  }

  abstract clone(order?: number): TelemetryItem;

  formatValue(value: number | undefined | null): string {
    if (value === undefined)
      return "Unknown";
    if (value === null)
      return "N/A";
    return formatNumber(value);
  }

  abstract fetchData(range: Interval): Promise<any>;
}

export class TelemetryPointItem extends TelemetryItem {
  point: TelemetryPoint;

  constructor(point: TelemetryPoint, style?: Partial<ItemStyle>, order?: number) {
    super(style, order);
    this.point = point;
    if (!style?.plotStyle && this.isEnumLike)
      this.style.plotStyle = 'regions';
  }

  get name(): string {
    return this.point.displayName ?? this.point.name ?? "unknown";
  }

  get unit(): string | null {
    return this.point.unit;
  }

  get dataType(): DataType {
    return this.point.dataType;
  }

  clone(order?: number): TelemetryPointItem {
    return new TelemetryPointItem(this.point, this.style, order ?? this.order);
  }

  formatValue(value: number): string {
    if (this.isEnumLike) {
      const valuesText = this.point.meta.valuesText ?? (
          // provide default "true"/"false" text for booleans
          this.dataType === DataType.BOOLEAN
              ? {"0": "false", "1": "true"}
              : {}
      );
      const text = valuesText[String(value)];
      if (valuesText && text !== undefined)
        return text;
    }
    return super.formatValue(value);
  }

  async fetchData(range: Interval): Promise<TelemetryReading[]> {
    const {siteId} = useFLMStore();
    return await queryClient.fetchQuery({
      queryKey: ['telemetryReading', this.point.id, range],
      queryFn: async () => {
        return await client.telemetry.getTelemetryReadings(siteId!, this.point.id, range.start, range.end);
      },
    });
  }
}

export class TelemetryAccountItem extends TelemetryItem {
  account: Account;
  protected _unit = 'kW';

  constructor(account: Account, style?: Partial<ItemStyle>, order?: number) {
    super(style, order);
    this.account = account;
  }

  get name(): string {
    if (this.account.label)
      return `Demand: Account #${this.account.id} (${this.account.label})`;
    return `Demand: Account #${this.account.id}`;
  }

  clone(order?: number): TelemetryAccountItem {
    return new TelemetryAccountItem(this.account, this.style, order ?? this.order);
  }

  async fetchData(range: Interval): Promise<TelemetryReading[]> {
    const {siteId, site} = useFLMStore();

    const fullAccount = site!.accounts.find((a) => a.id === this.account.id)!;
    const freq = Math.max(...fullAccount.meters.map((m) => m.freq));

    const data = await queryClient.fetchQuery(getQuery(
        client.site.getAccountUsage,
        siteId!,
        this.account.id,
        range.start,
        range.end,
        freq === 15 ? '15T' : '1H',
    ));
    return data.rows.map((row) => ({
      dt: row.dt,
      value: freq === 15 && row.usage !== null ? 4 * row.usage : row.usage,
    }));
  }
}

export class TelemetryMeterItem extends TelemetryItem {
  meter: Meter;
  protected _unit = 'kW';

  constructor(meter: Meter, style?: Partial<ItemStyle>, order?: number) {
    super(style, order);
    this.meter = meter;
  }

  get name(): string {
    if (this.meter.label)
      return `Demand: Meter #${this.meter.id} (${this.meter.label})`;
    return `Demand: Meter #${this.meter.id}`;
  }

  clone(order?: number): TelemetryMeterItem {
    return new TelemetryMeterItem(this.meter, this.style, order ?? this.order);
  }

  async fetchData(range: Interval): Promise<any> {
    const {siteId} = useFLMStore();
    const data = await queryClient.fetchQuery(getQuery(
        client.site.getMeterUsage,
        siteId!,
        this.meter.id,
        range.start,
        range.end,
        this.meter.freq === 15 ? '15T' : '1H',
    ));
    return data.rows.map((row) => ({
      dt: row.dt,
      value: this.meter.freq === 15 && row.usage !== null ? 4 * row.usage : row.usage,
    }));
  }
}

export class TelemetrySubmeterFieldItem extends TelemetryItem {
  submeterField: SubmeterField;
  protected _unit = 'kW';

  constructor(submeterField: SubmeterField, style?: Partial<ItemStyle>, order?: number) {
    super(style, order);
    this.submeterField = submeterField;
  }

  get name(): string {
    const name = this.submeterField.displayName ?? this.submeterField.sourceName;
    return `Submeter: ${name}`;
  }

  clone(order?: number): TelemetrySubmeterFieldItem {
    return new TelemetrySubmeterFieldItem(this.submeterField, this.style, order ?? this.order);
  }

  async fetchData(range: Interval): Promise<TelemetryReading[]> {
    const {siteId} = useFLMStore();
    const data = await queryClient.fetchQuery({
      queryKey: ['submeterReadings', this.submeterField.id, range.start, range.end],
      queryFn: async () => {
        return client.site.getSubmeterReadings(siteId!, this.submeterField.id!, range.start, range.end);
      },
    });
    // freq is always 15 minutes, convert kWh to kW
    return data.rows.map((row) => ({
      dt: row.dt,
      value: row.usage !== null ? 4 * row.usage : row.usage,
    }));
  }
}

export class TelemetryEventItem extends TelemetryItem {
  readonly SOURCE_TYPE = SourceType.PERIODS;
  protected _dataType: DataType = DataType.BOOLEAN;
  protected _dataGroupingApproximation: DataGroupingApproximationValue | null = null;

  constructor(style?: Partial<Omit<ItemStyle, 'plotStyle'>>) {
    super({...style, plotStyle: 'regions'}, -2);
  }

  get name(): string {
    return "FLM Event";
  }

  clone(): TelemetryEventItem {
    return new TelemetryEventItem(this.style);
  }

  formatValue(value: number): string {
    return value ? "Active" : "Inactive";
  }

  async fetchData(range: Interval): Promise<ValuePeriod<DateTime>[]> {
    // TODO: would be more efficient to just have an endpoint that returns the intervals
    const {siteId} = useFLMStore();

    const data = await queryClient.fetchQuery(getEventPerformanceQueryKey(siteId));
    return data.raw.map((event) => {
      return event.intervals.filter((interval) => {
        return range.overlaps(interval);
      }).map((interval) => ({
        start: interval.start,
        end: interval.end,
        value: 1,
      }));
    }).flat();
  }
}

export class TelemetryUpcomingEventItem extends TelemetryEventItem {
  readonly SOURCE_TYPE = SourceType.PERIODS;
  protected _dataType: DataType = DataType.BOOLEAN;
  protected _dataGroupingApproximation: DataGroupingApproximationValue | null = null;

  get name(): string {
    return "FLM Event";
  }

  clone(): TelemetryUpcomingEventItem {
    return new TelemetryUpcomingEventItem(this.style);
  }

  async fetchData(): Promise<ValuePeriod<DateTime>[]> {
    const {siteId} = useFLMStore();
    const upcomingEvents = await queryClient.fetchQuery(getQuery(
        client.site.getUpcomingEvents,
        siteId!,
    ));
    return upcomingEvents.upcomingEvents.flatMap((event: SiteEvent) => {
      return event.intervals.map((eti) => {
        return {
          start: eti.interval.start,
          end: eti.interval.end,
          value: 1,
          label: eti.level?.name ?? "Active",
        };
      });
    });
  }
}

export class TelemetryPotentialEventItem extends TelemetryEventItem {
  readonly SOURCE_TYPE = SourceType.PERIODS;
  protected _dataType: DataType = DataType.BOOLEAN;
  protected _dataGroupingApproximation: DataGroupingApproximationValue | null = null;

  get name(): string {
    return "Potential Event";
  }

  formatValue(value: number): string {
    return value ? "Active" : "None";
  }

  clone(): TelemetryPotentialEventItem {
    return new TelemetryPotentialEventItem(this.style);
  }

  async fetchData(): Promise<ValuePeriod<DateTime>[]> {
    const {siteId} = useFLMStore();
    const upcomingEvents = await queryClient.fetchQuery(getQuery(
        client.site.getPotentialEvents,
        siteId!,
    ));
    return upcomingEvents.events.flatMap((event: DailyEventPrediction) => {
      return event.windows.map((interval) => {
        return {
          start: interval.start,
          end: interval.end,
          value: 1,
        };
      });
    });
  }
}


export class TelemetrySignalItem extends TelemetryItem {
  protected _dataGroupingApproximation: DataGroupingApproximationValue | null = null;

  constructor(style?: Partial<ItemStyle>) {
    super(style, -1);
  }

  get name(): string {
    return "FLM Signal";
  }

  clone(): TelemetrySignalItem {
    return new TelemetrySignalItem(this.style);
  }

  async fetchData(range: Interval): Promise<TelemetryReading[]> {
    const {siteId} = useFLMStore();
    const data = await queryClient.fetchQuery({
      queryKey: ['flmSignal', siteId!, range.start, range.end],
      queryFn: async () => {
        return client.site.getFLMSignals(siteId!, range.start, range.end);
      },
    });

    return data.rows.map((row) => ({
      dt: row.dt,
      value: row.signal,
    }));
  }
}

interface WeatherField {
  value: string;
  name: string;
  unit: string;
  precision?: number | undefined;
}

export abstract class BaseTelemetryWeatherItem extends TelemetryItem {
  static readonly FIELDS: WeatherField[];

  field: WeatherField;
  protected _dataType: DataType = DataType.NUMBER;

  constructor(field: WeatherField, style?: Partial<ItemStyle>, order?: number) {
    super(style, order);
    this.field = field;
  }

  static getField(value: string): WeatherField {
    if (!this.FIELDS.map((field) => field.value).includes(value))
      throw new Error(`Unknown weather field: ${value}`);
    return this.FIELDS.find((field) => field.value === value)!;
  }

  get unit(): string {
    return this.field.unit;
  }

  get precision(): number | undefined {
    return this.field.precision ?? super.precision;
  }
}

// TWC weather
export class TelemetryWeatherItem extends BaseTelemetryWeatherItem {
  static readonly FIELDS: WeatherField[] = [
    {value: 'temperature', name: 'Temperature', unit: '°F'},
    {value: 'apparentTemperature', name: 'Apparent Temperature', unit: '°F'},
    {value: 'heatIndex', name: 'Heat Index', unit: '°F'},
    {value: 'windChillTemperature', name: 'Wind Chill', unit: '°F'},
    {value: 'dewPointTemperature', name: 'Dew Point Temperature', unit: '°F'},
    {value: 'wetBulbTemperature', name: 'Wet Bulb Temperature', unit: '°F'},
    {value: 'humidity', name: 'Humidity', unit: '%'},
    {value: 'cloudCover', name: 'Cloud Cover', unit: '%'},
    {value: 'windSpeed', name: 'Wind Speed', unit: 'mph'},
    {value: 'windGust', name: 'Wind Gust', unit: 'mph'},
    {value: 'precipitation', name: 'Precipitation', unit: 'in', precision: 2},
    {value: 'snowfall', name: 'Snowfall', unit: 'in', precision: 2},
    {value: 'globalHorizontalIrradiance', name: 'Global Horizontal Irradiance', unit: 'W/sqm'},
    {value: 'directNormalIrradiance', name: 'Direct Normal Irradiance', unit: 'W/sqm'},
  ];

  get name(): string {
    return `Weather (${this.field.name})`;
  }

  clone(order?: number): TelemetryWeatherItem {
    return new TelemetryWeatherItem(this.field, this.style, order ?? this.order);
  }

  async _fetch(range: Interval): Promise<WeatherObservationRow[]> {
    const {site} = useFLMStore();
    const data = await queryClient.fetchQuery({
      queryKey: ['weatherObservations', site!.weatherStationId, range.start, range.end],
      queryFn: async () => {
        return await client.telemetry.getWeatherObservations(site!.weatherStationId, range.start, range.end);
      },
    });
    return data.rows;
  }

  async fetchData(range: Interval): Promise<TelemetryReading[]> {
    const rows = await this._fetch(range);
    return rows.map((row) => {
      let value = row[this.field.value];

      // backend saves between 0 & 1, chart expects between 0 & 100 for %
      if (this.field.unit === '%')
        value = value * 100;

      return {
        dt: row.dt,
        value,
      };
    });
  }
}

export class TelemetryWeatherForecastItem extends TelemetryWeatherItem {
  get name(): string {
    return `Weather Forecast: ${this.field.name}`;
  }

  clone(order?: number): TelemetryWeatherForecastItem {
    return new TelemetryWeatherForecastItem(this.field, this.style, order ?? this.order);
  }

  async _fetch(): Promise<WeatherObservationRow[]> {
    const {site} = useFLMStore();
    const data = await queryClient.fetchQuery(getQuery(
        client.telemetry.getWeatherForecast,
        site!.cityId,
    ));
    return data.rows;
  }
}


type MalloryStationName = "Base" | "Mid" | "Top";

export class MalloryForecastItem extends BaseTelemetryWeatherItem {
  readonly SOURCE_TYPE = SourceType.ROWRANGES;
  static readonly FIELDS: WeatherField[] = [
    {value: 'parsedTemperatureRange', name: 'Temperature', unit: '°F'},
    {value: 'parsedWetBulbRange', name: 'Wet Bulb Temperature', unit: '°F'},
    {value: 'humidity', name: 'Humidity', unit: '%'},
  ];

  station: MalloryStationName;

  constructor(
      field: WeatherField,
      station: MalloryStationName,
      style?: Partial<ItemStyle>,
      order?: number,
  ) {
    const plotStyle = style?.plotStyle ?? (field.value === 'humidity' ? 'step' : 'arearangestep');
    if (field.value !== 'humidity' && !plotStyle.includes('arearange'))
      throw new Error(`Invalid plot style for MalloryForecastItem: ${style?.plotStyle}: range type required`);

    super(field, {plotStyle, ...style}, order);
    this.field = field;
    this.station = station;
  }

  get name(): string {
    return `Mallory Forecast (${this.station}): ${this.field.name}`;
  }

  clone(order?: number): MalloryForecastItem {
    return new MalloryForecastItem(
        this.field,
        this.station,
        this.style,
        order ?? this.order,
    );
  }

  async fetchData(): Promise<TelemetryReadingRange[]> {
    const {siteId} = useFLMStore();
    const data = await queryClient.fetchQuery(getQuery(client.site.getMalloryForecast, siteId!));
    return data.rows.filter((row) => {
      return row.station === this.station;
    }).map((row) => {
      const value = row[this.field.value];
      return ({
        // shift the forecast from the arbitrary 3a/3p day/night times to align it on the chart
        // selection of 8a/8p isn't really informed by anything smart, but it looks good
        // and covers the expected low being at ~sunrise and high in mid-late afternoon
        dt: row.dt.set({hour: row.dt.hour < 12 ? 8 : 20}),

        // kinda cheating a little bit returning this as a rows of ranges for humidity
        // when it's really just rows of scalars
        // but highcharts doesn't seem to care about the extra element
        // and this saves having to make SOURCE_TYPE dynamic for now
        value: typeof value === 'number' ? [value, value] : value,
      });
    });
  }
}


export class TelemetrySiteForecastItem extends TelemetryItem {
  protected _unit = 'kW';

  get name(): string {
    return "Usage Forecast";
  }

  clone(order?: number): TelemetrySiteForecastItem {
    return new TelemetrySiteForecastItem(this.style, order ?? this.order);
  }

  async fetchData(): Promise<TelemetryReading[]> {
    const {siteId} = useFLMStore();
    const data = await queryClient.fetchQuery(getQuery(client.site.getSiteForecast, siteId!));
    return data.rows.map((row) => ({
      dt: row.dt,
      value: row.forecast,
    }));
  }
}

