import api from "@/api";
import {Site, Utility} from "@/stores/flm/types";
import {UtilityAPIClient} from "@/api/client/utility";
import {UserAPIClient} from "@/api/client/user";
import {SiteAPIClient} from "@/api/client/site";
import {TelemetryAPIClient} from "@/api/client/telemetry";

export interface IndexResponse {
  utilities: Array<Utility>;
  sites: Array<Site>;
}

export class APIClient {
  site: SiteAPIClient;
  telemetry: TelemetryAPIClient;
  user: UserAPIClient;
  utility: UtilityAPIClient;

  constructor() {
    this.site = new SiteAPIClient();
    this.telemetry = new TelemetryAPIClient();
    this.user = new UserAPIClient();
    this.utility = new UtilityAPIClient();
  }

  async getIndex(): Promise<IndexResponse> {
    const resp = await api.get("/index/");
    return resp.data;
  }
}

export const client = new APIClient();
