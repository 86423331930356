import {NavMenuItem} from "@/components/navigation/types";

const userMenu: Array<NavMenuItem> = [
  // user dropdown menu
  {icon: "mdi-cog-outline", text: "Settings", to: {name: 'settings'}},
  {icon: "mdi-account-box-outline", text: "Account", to: {name: 'profile'}},
  {icon: "mdi-logout-variant", text: "Logout", to: {name: 'logout'}},
];

export default {
  user: userMenu,
};
