import {defineStore} from "pinia";
import {FLMState, Site, SiteRoles, Utility, UtilityId, UtilityRoles} from "@/stores/flm/types";
import {client} from "@/api/client";

export const useFLMStore = defineStore('flm', {
  state: (): FLMState => ({
    utilities: {},
    utilityId: null,

    // selected site
    siteId: null,
    // {siteid: {site},...}
    sites: {},
  }),
  getters: {
    site(): Site | null {
      if (this.siteId === null)
        return null;
      return this.sites[this.siteId] ?? null;
    },
    utility(): Utility | null {
      if (this.utilityId === null)
        return null;
      return this.utilities[this.utilityId] ?? null;
    },
    siteRoles(): SiteRoles | null {
      return this.site?.roles ?? null;
    },
    utilityRoles(): UtilityRoles | null {
      return this.utility?.roles ?? null;
    },
    hasAnyUtility(): boolean {
      return Object.keys(this.utilities).length > 0;
    },
  },
  actions: {
    setSelectedUtility(utilityId: UtilityId) {
      this.utilityId = utilityId;
      localStorage.setItem("selectedUtility", utilityId);
    },
    setDefaultSelectedUtility() {
      const utilities: any = Object.values(this.utilities);
      if (utilities.length > 0) {
        const selectedUtility = localStorage.getItem("selectedUtility");
        this.setSelectedUtility((selectedUtility! in this.utilities) ? selectedUtility : utilities[0].id);
      }
    },

    setSelectedSite(siteId: number) {
      this.siteId = siteId;
      localStorage.setItem("selectedSite", siteId.toString());
    },
    setDefaultSelectedSite() {
      const sites: any = Object.values(this.sites);
      if (sites.length > 0) {
        const selectedSite = localStorage.getItem("selectedSite");
        this.siteId = (selectedSite! in this.sites) ? Number(selectedSite) : sites[0].id;
      }
    },

    async fetchIndex() {
      const data = await client.getIndex();

      const utilities = {};
      data.utilities.forEach((utility) => utilities[utility.id] = utility);
      this.utilities = utilities;
      this.setDefaultSelectedUtility();

      const sites = {};
      data.sites.forEach((site) => sites[site.id] = site);
      this.sites = sites;
      this.setDefaultSelectedSite();
    },

    getSiteIdForAccount(accountId: string | null | undefined) {
      if (!accountId)
        return;
      return Object.values(this.sites).find((site: Site) => {
        return site.accounts.map((account) => account.id).includes(accountId);
      })?.id;
    },
  },
});

export type FLMStore = ReturnType<typeof useFLMStore>;
