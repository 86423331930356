import Vue from "vue";
import Keycloak from "keycloak-js";

const keycloak = new Keycloak({
  url: process.env.VUE_APP_KEYCLOAK_URL,
  realm: process.env.VUE_APP_KEYCLOAK_REALM,
  clientId: process.env.VUE_APP_KEYCLOAK_PUB_CLIENT_ID,
});

keycloak.onTokenExpired = () => {
  keycloak.updateToken(30).then(() => {
    // eslint-disable-next-line no-console
    console.debug("Refreshed token");
  }).catch(() => {
    console.error("Error updating token");
  });
};

Vue.prototype.$keycloak = keycloak;

export default keycloak;
