import { render, staticRenderFns } from "./NavMenuItem.vue?vue&type=template&id=2c6e7a27&"
import script from "./NavMenuItem.vue?vue&type=script&setup=true&lang=ts&"
export * from "./NavMenuItem.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports